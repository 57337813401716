import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { object, string, ref } from "yup";
import { client } from "../utils/Client";
import { Button, Input } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Invitation, Team } from "feathers-backend";
import Logo from "../assets/Icons";

type InvitationProps = {
  email: string;
  existingUser: boolean;
  teamId: string;
  teamName: string;
  _id: string;
};

export default function Signup() {
  const { id } = useParams();
  const [invitation, setInvitation] = useState<InvitationProps | null>();
  const navigate = useNavigate();

  const fetchInvitation = async (id: string) => {
    setInvitation((await client.service("invitations").get(id)) as any);
  };

  const reAuthenticate = async () => {
    try {
      if (await client.authentication.getAccessToken()) {
        const res = await client.authentication.reAuthenticate();
        if (res.user.email !== invitation?.email) {
          navigate("/");
        }
      }
    } catch (error) {}
  };

  const joinTeam = async () => {
    try {
      if (id) {
        await client.service("invitations").joinTeam({
          invitationId: id,
        });
      }
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    id && fetchInvitation(id);
    reAuthenticate();
  }, []);

  const passwordSchema = object({
    firstName: string().required("Please enter your first name"),
    lastName: string().required("Please enter your last name"),
    password: string()
      .required("Please enter a password")
      // check minimum characters
      .min(8, "Password must have at least 8 characters"),
    // different error messages for different requirements
    confirmPassword: string()
      .required("Please re-type your password")
      // use oneOf to match one of the values inside the array.
      // use "ref" to get the value of passwrod.
      .oneOf([ref("password")], "Passwords does not match"),
  });

  return (
    <div className="h-screen bg-cc-900">
      {invitation && (
        <div className="flex flex-col justify-center flex-1 min-h-full px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <Logo />
            <div className="mt-4 text-center">You have been invited to {invitation.teamName}</div>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-lg">
            {invitation.existingUser && (
              <div className="flex justify-end gap-2 mt-6 mb-3">
                <Button onPress={joinTeam} type="submit" color="primary" className="mx-auto">
                  Join team
                </Button>
              </div>
            )}
            {!invitation.existingUser && (
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  password: "",
                  confirmPassword: "",
                  email: invitation.email,
                }}
                validationSchema={passwordSchema}
                onSubmit={async (values, actions) => {
                  if (id) {
                    await client.service("invitations").createAccount({
                      invitationId: id,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      password: values.password,
                    });
                  }
                  navigate("/login");
                }}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    <div className="my-4">Create Account</div>
                    <div className="grid grid-cols-2 gap-4">
                      <Input
                        type="string"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.firstName}
                        name="firstName"
                        label="First Name"
                      />
                      <Input
                        type="string"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.lastName}
                        name="lastName"
                        label="Last Name"
                      />
                      <div className="col-span-2">
                        <Input
                          type="string"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.email}
                          name="email"
                          label="Email"
                          isDisabled
                        />
                      </div>
                      <Input
                        type="password"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.password}
                        name="password"
                        label="Password"
                      />
                      <Input
                        type="password"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.confirmPassword}
                        name="confirmPassword"
                        label="Confirm Password"
                      />
                    </div>
                    {props.errors && (
                      <div className="my-2 text-small text-placehold" id="feedback">
                        <div>{props.errors.password}</div>
                        <div>{props.errors.confirmPassword}</div>
                      </div>
                    )}
                    <div className="flex justify-end gap-2 mt-6 mb-3">
                      <Button type="submit" color="primary">
                        Create Account
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
