import { useContext } from "react";
import { Autocomplete, AutocompleteItem, Avatar } from "@nextui-org/react";
import { DataContext } from "../../../contexts/DataContext";
import { Customer, Opportunity } from "feathers-backend";
import { client } from "../../../utils/Client";

type SelectCustomerForOpportunityProps = {
  opportunity?: Opportunity;
  customer?: Customer;
};

export default function SelectContactForOpportunity({ opportunity, customer }: SelectCustomerForOpportunityProps) {
  let { contacts } = useContext(DataContext);

  // Filter the contacts based on customer.contacts array
  if (customer) {
    const customerContacts = contacts.filter((contact) => customer.contacts?.includes(contact._id.toString()));
    contacts = customerContacts;
  }
  // Create a new array of contacts sorted by name also only make sure the array contains customer id and name

  const sortedContacts = contacts
    .map((contact) => ({ _id: contact._id, name: contact.firstName + " " + contact.lastName }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const onSelectionChange = (key: React.Key | null) => {
    if (opportunity) {
      opportunity.contactId = key;
      client.service("opportunities").patch(opportunity._id.toString(), { contactId: key });
    }
  };

  const onClear = () => {
    if (opportunity) {
      opportunity.contactId = null;
      client.service("opportunities").patch(opportunity._id.toString(), { contactId: null });
    }
  };

  return (
    <div className="flex flex-wrap gap-4 md:flex-nowrap">
      {contacts && (
        <Autocomplete
          menuTrigger="focus"
          defaultItems={sortedContacts}
          label="Contact"
          size="sm"
          fullWidth={true}
          placeholder="Select a contact"
          labelPlacement="inside"
          isClearable={true}
          selectedKey={opportunity?.contactId?.toString()}
          onSelectionChange={onSelectionChange}
          onClear={onClear}
        >
          {(contact) => (
            <AutocompleteItem key={contact._id.toString()} textValue={contact.name}>
              <div className="flex items-center gap-2">
                <div className="flex flex-col">
                  <span className="text-small">{contact.name}</span>
                </div>
              </div>
            </AutocompleteItem>
          )}
        </Autocomplete>
      )}
    </div>
  );
}
