import { Board, Customer, Opportunity } from "feathers-backend";
import { useContext } from "react";
import { Button, Input, Textarea } from "@nextui-org/react";
import { BuildingOfficeIcon, DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../../contexts/UserContext";
import { AuthContext } from "../../../contexts/AuthContext";

type OpportunityDetailsProps = {
  opportunity: Opportunity;
  board?: Board;
  customer?: Customer;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
};

export default function OpportunityDetails({ opportunity, handleBlur, handleChange }: OpportunityDetailsProps) {
  const { me } = useContext(AuthContext);
  const { lang } = useContext(UserContext);
  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="col-span-2 mt-4 text-small">{lang("Details")}</div>
      <Input
        className="col-span-2"
        label={lang("Display name")}
        name="name"
        type="text"
        value={opportunity.name || ""}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <div className="col-span-2">
        <Textarea
          label={lang("Notes")}
          name="notes"
          type="text"
          value={opportunity.notes || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <Input
        label={lang("Expected value")}
        name="expectedValue"
        type="number"
        value={opportunity.expectedValue?.toString() || ""}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {me?.currentTeam?.integrations?.rexor && (
        <>
          <div className="col-span-2 mt-4 text-small">{lang("Rexor")}</div>
          <div className="col-span-2">
            <Input
              label={lang("Projektnummer")}
              name="rexorId"
              placeholder="Befintligt projekt i Rexor"
              type="text"
              size="sm"
              value={opportunity.rexorId || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </>
      )}
    </div>
  );
}
